// src/components/LeadGen.tsx
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

interface Feature {
    title: string;
    description: string;
    icon: JSX.Element;
}

const features: Feature[] = [
    {
        title: 'AI-Powered Prospecting',
        description: 'Identify and qualify high-value leads using advanced machine learning algorithms',
        icon: (
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <motion.path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                />
            </svg>
        )
    },
    {
        title: 'Multi-Channel Outreach',
        description: 'Orchestrate personalized campaigns across email, LinkedIn, and other platforms',
        icon: (
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <motion.path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                />
            </svg>
        )
    },
    {
        title: 'Behavioral Analytics',
        description: 'Track and analyze prospect engagement to optimize conversion strategies',
        icon: (
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <motion.path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                />
            </svg>
        )
    },
    {
        title: 'Smart Follow-ups',
        description: 'Automated, contextual follow-ups that maintain genuine conversation threads',
        icon: (
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <motion.path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                />
            </svg>
        )
    }
];

const LeadGen = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    const capabilities = [
        { title: 'Personalized Outreach', description: 'Tailored communication for each prospect' },
        { title: 'Smart Automation', description: 'Streamlined workflows without losing the human touch' },
        { title: 'Data-Driven Insights', description: 'Make informed decisions based on real engagement data' }
    ];

    return (
        <section ref={ref} className="py-24 relative overflow-hidden">
            {/* Background Effects remain the same */}

            <div className="max-w-7xl mx-auto px-4 relative z-10">
                {/* Header */}
                <motion.div
                    className="text-center mb-16"
                    initial={{ opacity: 0, y: 20 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.6 }}
                >
                    <span className="text-blue-500 text-sm font-semibold tracking-wider uppercase mb-4 block">
                        Featured Product
                    </span>
                    <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
                        LeadGen
                    </h2>
                    <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                        Intelligent lead generation platform that enhances your prospecting with AI
                    </p>
                </motion.div>

                {/* Core Capabilities instead of stats */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
                    {capabilities.map((capability, index) => (
                        <motion.div
                            key={index}
                            className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-white/10"
                            initial={{ opacity: 0, y: 20 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                        >
                            <h3 className="text-xl font-semibold text-white mb-2">{capability.title}</h3>
                            <p className="text-gray-400">{capability.description}</p>
                        </motion.div>
                    ))}
                </div>

                {/* Features Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    {features.map((feature, index) => (
                        <motion.div
                            key={index}
                            className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-white/10"
                            initial={{ opacity: 0, y: 20 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            whileHover={{ scale: 1.02 }}
                        >
                            <div className="flex items-start space-x-4">
                                <div className="p-3 bg-blue-500/10 rounded-lg text-blue-400">
                                    {feature.icon}
                                </div>
                                <div>
                                    <h3 className="text-xl font-semibold text-white mb-2">
                                        {feature.title}
                                    </h3>
                                    <p className="text-gray-400">
                                        {feature.description}
                                    </p>
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </div>

                {/* CTA Section */}
                <motion.div
                    className="text-center mt-16"
                    initial={{ opacity: 0 }}
                    animate={inView ? { opacity: 1 } : {}}
                    transition={{ duration: 0.5, delay: 0.6 }}
                >
                    <button className="px-8 py-3 bg-blue-500 hover:bg-blue-600 text-white rounded-lg font-semibold transition-colors duration-200">
                        Explore LeadGen
                    </button>
                </motion.div>
            </div>
        </section>
    );
};

export default LeadGen;