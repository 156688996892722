// src/components/Services.tsx
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

interface SubService {
    name: string;
    description: string;
}

interface Service {
    title: string;
    description: string;
    category: string;
    glyph: JSX.Element;
    subServices: SubService[];
}

const services: Service[] = [
    {
        title: 'Web Development',
        description: 'Stunning, responsive websites for the modern digital landscape',
        category: 'Core Solutions',
        glyph: (
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <motion.path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                />
            </svg>
        ),
        subServices: [
            { name: 'Full-Stack Development', description: 'End-to-end web solutions with modern technologies' },
            { name: 'React & Next.js', description: 'Dynamic, performant front-end applications' },
            { name: 'E-commerce Solutions', description: 'Scalable online shopping experiences' },
            { name: 'Progressive Web Apps', description: 'Mobile-first applications that work offline' }
        ]
    },
    {
        title: 'AI Solutions',
        description: 'Cutting-edge artificial intelligence solutions that transform businesses',
        category: 'AI Innovation',
        glyph: (
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <motion.path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                />
            </svg>
        ),
        subServices: [
            { name: 'AI Applications', description: 'Custom AI solutions for specific business needs' },
            { name: 'Machine Learning', description: 'Advanced ML models for complex problems' },
            { name: 'Natural Language Processing', description: 'Text analysis and understanding systems' },
            { name: 'Computer Vision', description: 'Image and video processing solutions' }
        ]
    },
    {
        title: 'Conversational AI',
        description: 'Advanced chatbots and virtual agents powered by cutting-edge AI',
        category: 'AI Innovation',
        glyph: (
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <motion.path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                />
            </svg>
        ),
        subServices: [
            { name: 'Custom GPT Models', description: 'Specialized language models for your domain' },
            { name: 'Virtual Agents', description: 'AI-powered customer service representatives' },
            { name: 'Voice Cloning & Synthesis', description: 'Natural voice generation and cloning' },
            { name: 'Multilingual Support', description: 'Communication in multiple languages' }
        ]
    },
    {
        title: 'Social Media & Marketing',
        description: 'Strategic social media management powered by AI',
        category: 'Digital Marketing',
        glyph: (
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <motion.path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                />
            </svg>
        ),
        subServices: [
            { name: 'AI Content Creation', description: 'Data-driven content generation and curation' },
            { name: 'Viral Marketing', description: 'Strategies for maximum social reach' },
            { name: 'Analytics & Insights', description: 'Deep understanding of performance metrics' },
            { name: 'Community Management', description: 'Engaged audience building and maintenance' }
        ]
    },
    {
        title: 'Advanced AI Tools',
        description: 'State-of-the-art AI technologies for innovative solutions',
        category: 'AI Innovation',
        glyph: (
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <motion.path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                />
            </svg>
        ),
        subServices: [
            { name: 'AI Agents & Automation', description: 'Autonomous AI agents for complex tasks' },
            { name: 'Predictive Analytics', description: 'Future trends and business insights' },
            { name: 'Multimodal AI', description: 'Combined text, image, and audio processing' },
            { name: 'AI Optimization', description: 'Performance tuning and scaling' }
        ]
    },
    {
        title: 'Brand & Design',
        description: 'AI-enhanced branding and design solutions',
        category: 'Creative Services',
        glyph: (
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <motion.path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M7 7h.01M7 3h5a1.99 1.99 0 013 0h5a2 2 0 012 2v14a2 2 0 01-2 2H4a2 2 0 01-2-2V5c0-1.1.9-2 2-2h3z"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                />
            </svg>
        ),
        subServices: [
            { name: 'Brand Strategy', description: 'AI-driven brand development and positioning' },
            { name: 'UI/UX Design', description: 'User-centered design solutions' },
            { name: 'Visual Identity', description: 'Comprehensive brand visual systems' },
            { name: 'Design Systems', description: 'Scalable design architecture' }
        ]
    },
    {
        title: 'Blockchain Development',
        description: 'Next-generation blockchain solutions for Web3 applications',
        category: 'Blockchain Innovation',
        glyph: (
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <motion.path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                />
            </svg>
        ),
        subServices: [
            { name: 'Solana Development', description: 'High-performance blockchain applications and SPL tokens' },
            { name: 'Smart Contracts', description: 'Secure, audited smart contract development' },
            { name: 'DeFi Solutions', description: 'Decentralized finance protocols and platforms' },
            { name: 'NFT Platforms', description: 'Custom NFT marketplaces and collections' }
        ]
    },
    {
        title: 'Cloud & DevOps',
        description: 'Modern cloud infrastructure and deployment solutions',
        category: 'Infrastructure',
        glyph: (
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <motion.path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                />
            </svg>
        ),
        subServices: [
            { name: 'Cloud Architecture', description: 'Scalable, resilient cloud infrastructure design' },
            { name: 'CI/CD Pipelines', description: 'Automated deployment and testing workflows' },
            { name: 'Kubernetes & Docker', description: 'Container orchestration and microservices' },
            { name: 'Infrastructure as Code', description: 'Automated infrastructure provisioning' }
        ]
    }
];

const Services = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    return (
        <section ref={ref} id="services" className="py-32 relative overflow-hidden">
            {/* Enhanced background effects */}
            <div className="absolute inset-0 bg-black">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent via-blue-900/5 to-transparent" />
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,_rgba(56,182,255,0.03)_0%,_transparent_60%)]" />
                <div className="absolute inset-0 backdrop-blur-3xl opacity-30" />
            </div>

            <div className="max-w-7xl mx-auto px-4 relative z-10">
                <motion.div
                    className="text-center mb-24"
                    initial={{ opacity: 0, y: 20 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.6 }}
                >
                    <h2 className="text-5xl md:text-6xl font-bold text-white mb-6">
                        Our Services
                    </h2>
                    <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                        Transforming businesses through innovative technology solutions
                    </p>
                </motion.div>

                {/* Services Grid */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                    {services.map((service, index) => (
                        <motion.div
                            key={service.title}
                            className="relative"
                            initial={{ opacity: 0, y: 20 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                        >
                            {/* Service Card */}
                            <div className="relative rounded-3xl overflow-hidden h-full transform transition-transform duration-300 hover:scale-[1.02]">
                                {/* Enhanced gradient overlay */}
                                <motion.div
                                    className="absolute inset-0 bg-gradient-to-r from-blue-600/20 via-purple-600/20 to-pink-600/20"
                                    initial={{ opacity: 0 }}
                                    whileHover={{ opacity: 1 }}
                                    transition={{ duration: 0.3 }}
                                />

                                {/* Content */}
                                <div className="bg-white/5 backdrop-blur-sm p-8 relative z-10 h-full border border-white/10">
                                    {/* Category tag */}
                                    <div className="absolute top-4 right-4">
                                        <span className="px-3 py-1 bg-white/10 rounded-full text-xs text-white/80">
                                            {service.category}
                                        </span>
                                    </div>

                                    <div className="flex flex-col space-y-6">
                                        {/* Icon */}
                                        <div className="p-4 rounded-2xl bg-gradient-to-r from-blue-500/10 to-purple-500/10 text-white w-fit">
                                            {service.glyph}
                                        </div>

                                        {/* Title and Description */}
                                        <div>
                                            <h3 className="text-2xl font-bold text-white mb-3">{service.title}</h3>
                                            <p className="text-gray-300 mb-6">{service.description}</p>

                                            {/* Sub-services */}
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                {service.subServices.map((subService, idx) => (
                                                    <motion.div
                                                        key={idx}
                                                        className="bg-black/30 rounded-xl p-4 border border-white/5 hover:border-white/20 transition-colors duration-300"
                                                        whileHover={{ scale: 1.02 }}
                                                        transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                                                    >
                                                        <h4 className="text-white font-semibold mb-1">
                                                            {subService.name}
                                                        </h4>
                                                        <p className="text-gray-400 text-sm">
                                                            {subService.description}
                                                        </p>
                                                    </motion.div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Animated border */}
                                    <motion.div
                                        className="absolute bottom-0 left-0 h-[2px] bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500"
                                        initial={{ width: 0 }}
                                        whileHover={{ width: '100%' }}
                                        transition={{ duration: 0.3 }}
                                    />
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Services;