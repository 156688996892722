// src/pages/Portfolio.tsx
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Particles from 'react-particles';
import { loadSlim } from "tsparticles-slim";
import { useCallback, useEffect, useState } from 'react';

interface Project {
    title: string;
    description: string;
    tag: string;
    gradient: string;
    stats?: {
        value: string;
        label: string;
    }[];
    technologies?: string[];
    features?: string[];
}

const projects: Project[] = [
    {
        title: 'Lawphoria',
        description: 'AI-powered legal assistant helping law practices streamline document processing and research.',
        tag: 'AI + LEGAL TECH',
        gradient: 'from-blue-600 to-violet-600',
        technologies: ['GPT-4o', 'LangChain', 'Next.js', 'TypeScript'],
        features: [
            'Document Analysis & Processing',
            'Case Law Research',
            'Legal Writing Assistance'
        ]
    },
    {
        title: 'Invoca',
        description: 'Modern billing system with integrated analytics and financial forecasting capabilities.',
        tag: 'FINTECH',
        gradient: 'from-violet-600 to-purple-600',
        technologies: ['Python', 'TensorFlow', 'PostgreSQL', 'React'],
        features: [
            'Payment Processing',
            'Revenue Analytics',
            'Automated Reconciliation'
        ]
    },
    {
        title: 'PicassoClub',
        description: 'AI art generation platform combining multiple models for creative professionals.',
        tag: 'AI + CREATIVE',
        gradient: 'from-purple-600 to-pink-600',
        technologies: ['Stable Diffusion', 'DALL-E', 'Vue.js', 'Python'],
        features: [
            'Multi-Model Generation',
            'Style Transfer',
            'Batch Processing'
        ]
    },
    {
        title: 'thetesseract.ai',
        description: 'Content generation platform powered by AI, helping creators produce quality content efficiently.',
        tag: 'CONTENT GEN',
        gradient: 'from-pink-600 to-red-600',
        technologies: ['GPT-4', 'BERT', 'Next.js', 'Node.js'],
        features: [
            'Multi-Format Content',
            'SEO Integration',
            'Brand Voice Training'
        ]
    },
    {
        title: 'Costmaster',
        description: 'Construction calculation software with predictive costing and resource optimization.',
        tag: 'CONSTRUCTION TECH',
        gradient: 'from-green-600 to-teal-600',
        technologies: ['Python', 'TensorFlow', 'React', 'MongoDB'],
        features: [
            'Cost Estimation',
            'Material Planning',
            'Timeline Forecasting'
        ]
    },
    {
        title: 'BillRight',
        description: 'Intelligent billing and inventory management system for small to medium businesses.',
        tag: 'INVENTORY + FINANCE',
        gradient: 'from-yellow-500 to-orange-500',
        technologies: ['Node.js', 'React', 'PostgreSQL', 'Redis'],
        features: [
            'Inventory Management',
            'Automated Billing',
            'Business Analytics'
        ]
    }
];

const Portfolio = () => {
    const [selectedProject, setSelectedProject] = useState<Project | null>(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const particlesInit = useCallback(async (engine: any) => {
        await loadSlim(engine);
    }, []);

    return (
        <div className="min-h-screen bg-black">
            <Navbar />

            {/* Enhanced Particles Background */}
            <Particles
                id="tsparticles"
                init={particlesInit}
                options={{
                    background: { color: { value: "#000000" } },
                    particles: {
                        color: { value: ["#0ff", "#88f", "#fff"] },
                        links: {
                            color: "#4080ff",
                            distance: 150,
                            enable: true,
                            opacity: 0.15,
                            width: 1,
                        },
                        move: {
                            enable: true,
                            speed: 0.5,
                            direction: "none",
                            random: true,
                            straight: false,
                            outModes: { default: "bounce" },
                        },
                        number: {
                            density: { enable: true, area: 1000 },
                            value: 100,
                        },
                        opacity: {
                            value: { min: 0.05, max: 0.25 },
                            animation: {
                                enable: true,
                                speed: 1,
                            },
                        },
                        shape: { type: "circle" },
                        size: {
                            value: { min: 1, max: 3 },
                            animation: {
                                enable: true,
                                speed: 2,
                                sync: false,
                            },
                        },
                    },
                }}
                className="absolute inset-0"
            />

            {/* Content */}
            <div className="relative z-10 pt-32 pb-20 px-4">
                <div className="max-w-7xl mx-auto">
                    <motion.div
                        className="text-center mb-16"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                    >
                        <motion.img
                            src="/assets/neuralwayLogo.svg"
                            alt="Neuralway"
                            className="h-16 mx-auto mb-8"
                            initial={{ scale: 0.8 }}
                            animate={{ scale: 1 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                        />
                        <motion.h1
                            className="text-5xl md:text-6xl font-bold text-white mb-6"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.3 }}
                        >
                            Our Portfolio
                        </motion.h1>
                        <motion.p
                            className="text-xl text-white/60 max-w-3xl mx-auto"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                        >
                            Discover our innovative solutions that are transforming industries
                        </motion.p>
                    </motion.div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {projects.map((project, index) => (
                            <ProjectCard
                                key={project.title}
                                project={project}
                                index={index}
                                onClick={() => setSelectedProject(project)}
                            />
                        ))}
                    </div>
                </div>
            </div>

            {/* Project Modal */}
            {selectedProject && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed inset-0 z-50 flex items-center justify-center px-4"
                >
                    <div
                        className="absolute inset-0 bg-black/80 backdrop-blur-xl"
                        onClick={() => setSelectedProject(null)}
                    />
                    <motion.div
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.9, opacity: 0 }}
                        className="relative bg-black/80 rounded-3xl overflow-hidden max-w-4xl w-full max-h-[80vh] overflow-y-auto"
                    >
                        <div className="p-8">
                            <div className="flex justify-between items-start mb-6">
                                <div>
                                    <div className="inline-block px-4 py-1 rounded-full bg-white/10 text-sm text-white/70 mb-4">
                                        {selectedProject.tag}
                                    </div>
                                    <h3 className="text-3xl font-bold text-white mb-2">{selectedProject.title}</h3>
                                    <p className="text-white/70">{selectedProject.description}</p>
                                </div>
                                <button
                                    onClick={() => setSelectedProject(null)}
                                    className="text-white/60 hover:text-white transition-colors"
                                >
                                    <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>

                            {/* Project Stats */}
                            {selectedProject.stats && (
                                <div className="grid grid-cols-3 gap-4 mb-8">
                                    {selectedProject.stats.map((stat, index) => (
                                        <div key={index} className="text-center p-4 bg-white/5 rounded-xl">
                                            <div className="text-2xl font-bold text-white mb-1">{stat.value}</div>
                                            <div className="text-sm text-white/60">{stat.label}</div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {/* Technologies */}
                            {selectedProject.technologies && (
                                <div className="mb-8">
                                    <h4 className="text-lg font-semibold text-white mb-4">Technologies</h4>
                                    <div className="flex flex-wrap gap-2">
                                        {selectedProject.technologies.map((tech, index) => (
                                            <span
                                                key={index}
                                                className="px-3 py-1 bg-white/10 rounded-full text-sm text-white/70"
                                            >
                                                {tech}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {/* Features */}
                            {selectedProject.features && (
                                <div>
                                    <h4 className="text-lg font-semibold text-white mb-4">Key Features</h4>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        {selectedProject.features.map((feature, index) => (
                                            <div
                                                key={index}
                                                className="flex items-center space-x-2 text-white/70"
                                            >
                                                <svg className="w-5 h-5 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                                </svg>
                                                <span>{feature}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </motion.div>
                </motion.div>
            )}

            <Footer />
        </div>
    );
};

const ProjectCard = ({ project, index, onClick }: any) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.6, delay: index * 0.1 }}
            whileHover={{ scale: 1.02 }}
            onClick={onClick}
            className="relative group cursor-pointer"
        >
            <div className="absolute inset-0 bg-gradient-to-r from-blue-600/20 via-purple-600/20 to-pink-600/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-2xl" />

            <div className="relative bg-white/5 backdrop-blur-sm rounded-2xl overflow-hidden border border-white/10">
                <div className="p-8">
                    <div className="space-y-4">
                        <div className="inline-block px-4 py-1 rounded-full bg-white/10 text-sm text-white/70">
                            {project.tag}
                        </div>

                        <h3 className="text-2xl font-bold text-white">{project.title}</h3>

                        <p className="text-white/70">{project.description}</p>

                        {/* Preview Stats */}
                        {project.stats && (
                            <div className="grid grid-cols-3 gap-2 mt-6">
                                {project.stats && project.stats.slice(0, 3).map((stat: { value: string; label: string }, index: number) => (
                                    <div key={index} className="text-center p-3 bg-white/5 rounded-lg">
                                        <div className="text-lg font-bold text-white mb-1">{stat.value}</div>
                                        <div className="text-xs text-white/60">{stat.label}</div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {/* Preview Technologies */}
                        {project.technologies && (
                            <div className="flex flex-wrap gap-2 mt-4">
                                {project.technologies.slice(0, 3).map((tech: string, index: number) => (
                                    <span
                                        key={index}
                                        className="px-2 py-1 bg-white/5 rounded-full text-xs text-white/60"
                                    >
                                        {tech}
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>

                    {/* Animated Border */}
                    <motion.div
                        className={`absolute bottom-0 left-0 h-px w-full bg-gradient-to-r ${project.gradient}`}
                        initial={{ width: 0 }}
                        whileHover={{ width: '100%' }}
                        transition={{ duration: 0.3 }}
                    />
                </div>
            </div>
        </motion.div>
    );
};

export default Portfolio;